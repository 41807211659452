export const professors = [
  {
    name: 'Benjamin D. Akers, S.T.D.',
    position: 'Associate Professor of Theology, Associate Dean',
  },
  {
    name: 'Michael Barber, Ph.D',
    position: 'Associate Professor of Scripture & Theology',
  },
  {
    name: 'Mark Giszczak, Ph.D.',
    position: 'Associate Professor of Sacred Scripture',
  },
  {
    name: 'Tim Gray, Ph.D.',
    position: 'Professor of Sacred Scripture, President',
  },
  {
    name: 'Scott Hefelfinger, Ph.D.',
    position: 'Assistant Professor of Theology',
  },
  {
    name: 'Sean Innerst, Ph.D',
    position: 'Academic Dean',
  },
  {
    name: 'Elizabeth Klein, Ph.D.',
    position: 'Assistant Professor of Theology',
  },
  {
    name: 'Brant Pitre, Ph.D.',
    position: 'Distinguished Research Professor of Sacred Scripture',
  },
  {
    name: 'Lucas Pollice, M.T.S.',
    position:
      'Associate Professor of Theology and Catechetics, Director, M.A. in Leadership for the New Evangelization',
  },
  {
    name: 'James Prothro, Ph.D.',
    position: 'Assistant Professor of Theology and Sacred Scripture',
  },
  {
    name: 'John Sehorn, Ph.D.',
    position: 'Assistant Professor of Theology',
  },
  {
    name: 'Gwen Adams, Ph. D.',
    position: 'Visiting Assistant Professor of Theology',
  },
  {
    name: 'Bronwen McShea, M.T.S., Ph.D.',
    position: 'Visiting Assistant Professor of History',
  },
  {
    name: 'Fr. Daniel Moloney, Ph.D.',
    position: 'Visiting Assistant Professor of History',
  },
  {
    name: 'Edward Sri, S.T.D.',
    position: 'Visiting Professor of Theology',
  },
  {
    name: 'Jared Staudt, Ph.D.',
    position: 'Visiting Associate Professor of Theology',
  },
];
