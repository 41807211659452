import React from 'react';
import SEO from '../components/Seo';
import { professors } from '../data/professors';

export default function ProfessorsPage() {
  return (
    <>
      <SEO
        title="Professors"
        description="Faculty at the Augustine Institute seek to teach and live in full accord with the Catholic Faith as expounded by the Magisterium, in keeping with the directives of St. John Paul II’s apostolic constitution Ex corde Ecclesiae."
      />
      <main className="page-professors">
        <section className="container section-m-t section-m-b">
          <div className="row">
            <div className="col-xs-12 col-lg-10 col-lg-offset-1">
              <h2>Professors</h2>
              <div className="text-columns">
                <p>
                  Faculty at the Augustine Institute seek to teach and live in
                  full accord with the Catholic Faith as expounded by the
                  Magisterium, in keeping with the directives of St. John Paul
                  II’s apostolic constitution Ex corde Ecclesiae.
                </p>
                <p>
                  Teaching, mentoring, and responding to students’ work are
                  tasks central to the work of the faculty.
                </p>
                <p>
                  The faculty also express a common pursuit of wisdom by means
                  of academic research, writing, and speaking, as well as
                  writing for popular audiences and speaking in apostolic
                  contexts such as parishes.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container section-m-t page-m-b">
          <div className="row">
            <div className="col-xs-12 col-lg-11 col-lg-offset-1">
              <div className="professors">
                <ul>
                  {professors.map((prof, i) => (
                    <li key={`prof-${i}`} className="professor">
                      <div className="professor__inner">
                        <h3 className="h5 professor__name">{prof.name}</h3>
                        <h4 className="h6 professor__position">
                          {prof.position}
                        </h4>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
